import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import VueRouter from "vue-router";
import router from "./router";
import i18n from "./i18n";


import alertModal from "./alert.js";

 
import * as Sentry from "@sentry/vue";
import * as BrowserSentry from "@sentry/browser";

import "vue-multiselect/dist/vue-multiselect.min.css";
import LottieAnimation from "lottie-web-vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faInfinity,
  faArrowCircleLeft,
  faArrowLeft,
  faArrowRight,
  faArrowCircleRight,
  faExternalLinkAlt,
  faCaretDown,
  faCaretUp,
  faTrashAlt,
  faCheck,
  faMinus,
  faMobileAlt,
  faDesktop,
  faPlus,
  faPlay,
  faPause,
  faTrash,
  faCalendarAlt,
  faTimes,
  faEye,
  faPencilAlt,
  faUndo,
  faList,
  faSearch,
  faExclamationCircle,
  faChartBar,
  faRecycle,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";
import {
  faSmile,
  faClone,
  faQuestionCircle,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
import apiClientPlugin from "./services/apiClientPlugin";
import HighchartsVue from "highcharts-vue";

import { helper } from "@/mixins/CopyCode";

// Validation
import { regex, required } from "vee-validate/dist/rules";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

library.add(
  faCheckCircle,
  faClone,
  faQuestionCircle,
  faSmile,
  faInfinity,
  faArrowCircleLeft,
  faArrowLeft,
  faArrowRight,
  faArrowCircleRight,
  faExternalLinkAlt,
  faCaretDown,
  faCaretUp,
  faTrashAlt,
  faCheck,
  faMinus,
  faMobileAlt,
  faDesktop,
  faPlus,
  faPlay,
  faPause,
  faTrash,
  faCalendarAlt,
  faTimes,
  faEye,
  faPencilAlt,
  faUndo,
  faList,
  faSearch,
  faExclamationCircle,
  faChartBar,
  faRecycle,
  faCommentDots
);

Vue.config.productionTip = false;

// Initialize Sentry
Sentry.init({
  Vue,
  release: process.env.GIT_TAG ?? "unknown",
  dsn: "https://320a2180a9554388ad80074e14da41d1@errors.house/16",
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration({router}),
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

BrowserSentry.setTag("commit_hash", process.env.COMMIT_HASH);

Vue.prototype.$helper = helper;
Vue.prototype.$alert = alertModal;

Vue.use(LottieAnimation);
Vue.use(VueRouter);
Vue.use(HighchartsVue);
Vue.use(apiClientPlugin);

extend("regex", regex);
extend("required", required);

const loadLayouts = () => {
  return Promise.all([
    import("@/layouts/DefaultLayout"),
    import("@/layouts/AuthLayout"),
    import("@/layouts/PageLayout"),
    import("@/layouts/PanelLayout"),
    import("@/layouts/ReviewLayout"),
  ])
    .then(
      ([DefaultLayout, AuthLayout, PageLayout, PanelLayout, ReviewLayout]) => {
        Vue.component("DefaultLayout", DefaultLayout.default);
        Vue.component("AuthLayout", AuthLayout.default);
        Vue.component("PageLayout", PageLayout.default);
        Vue.component("PanelLayout", PanelLayout.default);
        Vue.component("ReviewLayout", ReviewLayout.default);
      }
    )
    .catch((error) => {
      console.error("Error loading layouts:", error);
    });
};

loadLayouts().then(() => {
  new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
    beforeCreate() {
      document.querySelector(".notice-work-conditions")?.remove();
    },
  }).$mount("#app");
});

const lazyLoadPlugins = async () => {
  const [
    VueApexCharts,
    Multiselect,
    VueMeta,
    VueScrollTo,
    VModal,
    VTooltip,
    Vuelidate,
    VueIntercom,
    VueMask,
    SvgSprite,
    VueFlags,
    VueGtm,
    vClickOutside,
  ] = await Promise.all([
    import("vue-apexcharts"),
    import("vue-multiselect"),
    import("vue-meta"),
    import("vue-scrollto"),
    import("vue-js-modal/dist/index.nocss.js"),
    import("v-tooltip"),
    import("vuelidate"),
    import("vue-intercom"),
    import("v-mask"),
    import("vue-svg-sprite"),
    import("@growthbunker/vueflags"),
    import("@gtm-support/vue2-gtm"),
    import("v-click-outside"),
  ]);

  Vue.component("apexchart", VueApexCharts.default);
  Vue.component("multiselect", Multiselect.default);

  Vue.use(VueMeta.default);
  Vue.use(VueScrollTo.default);
  Vue.use(VModal.default);
  Vue.use(VTooltip.default);
  Vue.use(Vuelidate.default);
  Vue.use(VueIntercom.default, { appId: "eyauf2ue" });
  Vue.use(VueMask.default);
  Vue.use(SvgSprite.default, {
    url: require("./assets/sprite/icons.svg"),
    class: "svg-sprite-icon icon-symbol",
  });
  Vue.use(VueFlags.default, { iconPath: "/images/flags/" });
  Vue.use(VueGtm.default, {
    id: "GTM-PDDGLK2",
    defer: false,
    compatibility: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: true,
  });
  Vue.use(vClickOutside.default);
};

lazyLoadPlugins();
